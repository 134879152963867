<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">Customer</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail">
          <b-row style="margin-left: 10px;">
       
              <b-col cols="7" style="margin: 0 10px 10px 0;background-color: #172A46;height:calc(90vh - 20px);" >
                <div style="position:absolute; top: -50px; right: 0px; width:350px;padding:10px;">
          
                </div>
                <div class="log_box" style="padding: 15px 5px 10px 20px; ">
                  <h5 style="color:#fff;" >CUSTOMER</h5>
                </div>
                <!--div style="position:absolute; top: 20px; right: -30px; width:550px;padding:10px;background:#f2f2f;">
                    <span style="padding: 10px;"  >CUSTOMER : <b-select  size="sm" v-model="s_customer_no" :options="customer_name" style="width:150px;" ></b-select>
                    </span>

                    <span style="padding: 10px;"  >DEVICE TYPE : <b-select  size="sm" v-model="device_type" :options="device_type_name" style="width:120px;"></b-select>
                    </span>
                 
                </div-->
                <!-- <div style="background-color:white; padding: 20px 10px;"> -->
                <div style="padding: 20px 10px;" class="device_table">
                
                    <ServerSideTable ref="table_customer"                                   
                                   :fixed-header="true"                                   
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"      
                                   :adv_search="adv_search"                             
                                   :select="true"  
                                   :search="true"                                
                                   :max_height="contents_height-60 +'px'"  
                                    @on-row-click="onRowClick"                              
                              
                  />
                 </div>
              </b-col>
            
              <!-- <b-col style=" margin: 0 0 10px;background-color:white;"> -->
              <b-col style=" margin: 0 0 10px;background-color:#172a46;">
                <div style="padding: 0px 30px 0 40px; margin:0 10px ; background-color:#172a46;" >
                <div class="log_box" style="padding: 40px 5px 10px 0px;"  >
                   <span style="float: right;padding: 10px; margin-right: -20px;margin-top:-20px;">
                      
                      <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style="background:#28af81;" @click="new_customer">New Customer</button></span>
                      
                  </span>
                  <h5 style="color:#fff;">CUSTOMER INFORMATION</h5>
                  
                 
                  <hr/>
                </div>
                    <b-row >
                        <b-col sm="4">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row >
                        <b-col sm="4">
                            <label>Name</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['name']" ></b-input>
                        </b-col>
                    </b-row>                   
                    <!--b-row>
                        <b-col sm="4">
                            <label>Name</label>
                        </b-col>
                        <b-col sm="8">                      
                            <b-select class="facility__input" style="margin-bottom:8px;" size="sm" v-model="properties['name']" :options="device_option" :disabled="new_show" 
                             v-on:change="getSelectedItem"  ></b-select>
                        </b-col>
                    </b-row-->                    
                    <b-row v-if = "!new_show">
                        <b-col sm="4">
                            <label>Customer No</label>
                        </b-col>
                        <b-col sm="3"> 
                          <b-select class="facility__input" style="margin-bottom:8px;" size="sm" v-model="country_phone" :options="country_phone_option"
                             v-on:change="getSelectedCountry"  ></b-select>
                        
                        </b-col> 
                        <b-col sm="3">
                             <b-select class="facility__input" style="margin-bottom:8px;" size="sm" v-model="country_area_phone" :options="country_area_phone_option"
                              ></b-select>
                           <!--b-input  v-model="country_area_phone"  maxlength="4" :disabled="new_show"  /-->
                        </b-col> 
                        <b-col sm="2">  
                            <b-input size="sm" v-model="country_area_seq" maxlength="4" disabled ></b-input>  
                        </b-col>
                    </b-row>
                    <!--b-row>
                        <b-col sm="4">
                            <label>Customer Name</label>
                        </b-col>
                        <b-col sm="8">                           
                            <b-select  size="sm" v-model="properties['customer_no']" :options="i_customer_name" style="width:300px;margin-bottom:8px;" :disabled="change_customer" />
                            
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <label></label>
                           
                            <span style="float:right;color:#a7e1ec;">(* 장비가 지점에 등록되어 있는 경우, 앱에서 먼저 해제 후 장비 고객 변경 가능) </span>
                        </b-col>
                    </b-row-->
                    <b-row v-if = "new_show">
                        <b-col sm="4">
                            <label>Customer No</label>
                        </b-col>
                        <b-col sm="8"> 
                            <b-input size="sm" v-model="properties['customer_no']" disabled></b-input>
                        </b-col>
                    </b-row>
       

                  
                    <b-row >
                        <b-col sm="4">
                            <label>SW license Type</label>
                        </b-col>
                        <b-col sm="8">
                            <!--b-input size="sm" v-model="properties['sw_license_type']" disabled></b-input-->
                            <b-select  size="sm" v-model="properties['sw_license_type']" :options="sw_license_type_opt" style="width:300px;margin-bottom:8px;" :disabled="change_customer" />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label>SW license_count</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['sw_license_count']" disabled ></b-input>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="4">
                            <label>Service Start_date</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['service_start_date']" disabled ></b-input>
                        </b-col>
                    </b-row> 
                     <b-row>
                        <b-col sm="4">
                            <label>Service End_date</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['service_end_date']"  ></b-input>
                        </b-col>
                    </b-row>   
                                                                                                                                                    
                    <b-row>
                        <b-col sm="4">
                            <label>Register DateTime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <!--b-row>
                        <b-col sm="4">
                            <label>Update DateTime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['update_date']" disabled ></b-input>
                        </b-col>
                    </b-row-->     
                                           
                  <b-row>
                        <b-col sm="4">
                            <label>location</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['shape']"  ></b-input>
                        </b-col>
                        <b-col sm="1">    
                            <b-button  @click="change_pos" ><i class='fas fa-pencil-alt' style='color:#fff;position:relative;'></i></b-button >
                        </b-col>
                    </b-row>
                  <b-row>
                        <b-col sm="4">
                            <label>Customer Image</label>
                        </b-col>
                        <b-col sm="4">
                              <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                <b-img thumbnail fluid :src="image_item" alt="image" bottom ></b-img>       
                                <Label for="ex_filename">                                   
                                    <i class='fas fa-pencil-alt' style='color:#fff;position:relative;'></i>
                                </Label>    
                                    <input type="file" id="ex_filename"  @change="previewMultiImage($event)" style="display: none;"/> 
                              </b-card>    
                                
                        </b-col>
                        <b-col sm="4">
                            <!--b-button  @click="change_image" ><i class='fas fa-pencil-alt' style='color:#fff;'></i></b-button -->
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="4">
                            <label>Status</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                        </b-col>
                    </b-row>    
                    <br/>                                                                      
                    <hr/>
                      <b-row class="facility-info-footer-row m-0 mb-3" style="padding : 0px;">
                        <b-col sm="9">
                          <!--<b-button @click="$refs.modal.hide()">Close</b-button> -->
                          <!--b-button @click="new_device()" block class="facility-info-footer__button facility-info-footer__button-cancel">New Device</b-button-->
                        </b-col>
                        <b-col sm="3">
                          <b-button @click="Update()" block class="facility-info-footer__button facility-info-footer__button-ok">Save</b-button>
                        </b-col>
                      </b-row>
                </div>  
              </b-col>  
          </b-row>
        </div>
        <LocationPop ref="LocationPop" @set_coordiate='set_coordiate' />
    </div>
</template>

<script>


import ServerSideTable from '@/components/table/ServerSideTable_device';
import LocationPop from './LocationPop' ;
import Compressor from 'compressorjs';
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    name: 'Customer',
    props: [
    ],
    watch: {
        s_customer_no() {      
           // this.go_search();             
        },
        device_type(){
         // this.go_search();
        }
    },
    data() {
        return {
            properties: [],
            change_customer : false,
            properties_bak: [],
            new_show : true,
            searchTerm: '',
            line_number : true,
            i_device_type :"",
            device_sn1:"",
            device_sn2:"",
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',
            selected_ts_flow: '1',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            mnf_date: '2020-11-01',
            image_item : '',
            coord_point : [],
            image_list : [],

            columns: [
                {label: 'ID', field: 'id'},
                {label: 'customer_no', field: 'customer_no'},         
                {label: 'name', field: 'name'},                           
                {label: 'sw_license_type', field: 'sw_license_type'},
                {label: 'sw_license_count', field: 'sw_license_count'},
                {label: 'register_datetime', field: 'register_datetime'},
                {label: 'service_start_date', field: 'service_start_date'},
                {label: 'service_end_date', field: 'service_end_date'},
                {label: 'status', field: 'status'},
              //  {label: 'shape', field: 'shape'},
                
            ],    
            columns1: [
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],            
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            device_option: [
                { value: 'SM1', text: 'SM1' },
                { value: 'SM2', text: 'SM2' },
                { value: 'ILOG', text: 'ILogger' },
            ],            
            table_customer: [],
            device_type:'',
            s_customer_no:'',
            table_device: [],
            ts_mnf: [],
            activeCheckIndex: null,
            server_name:[{value:1, text :"KOREA SERVER"},
                         {value:2, text :"INDONESIA SERVER"},
                         {value:3, text :"BATNAM SERVER"},
                         {value:4, text :"TANZANIA SERVER"},
                        ],
            sw_license_type_opt :[
                                    {value:0, text :"Standard"},
                                    {value:1, text :"Pro"},                              
                                 ],   
            country_phone : '082',                                                              
            country_phone_option : [{value:'', text :"ALL"},
                            ], 
            country_area_seq : '001',                           
            country_area_phone : '042',                            
            country_area_phone_option :  [{value:'', text :"ALL"},],                            
            i_customer_name : [],      
            i_customer_id : [],                                                                    
            server_id : 1,
            clicked_l_idx: -1,
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
      ],
        }
    },
    methods: {
      current_date(today, a){
        var year = today.getFullYear();
        var month = ('0' + (today.getMonth() + 1)).slice(-2);
        var day = ('0' + today.getDate()).slice(-2);
       // var hours = ('0' + today.getHours()).slice(-2);
      //  this.current_hour = hours
      // var minutes = ('0' + today.getMinutes()).slice(-2);
      // var dateString = year + '-' + month  + '-' + day +" "+hours+ ':' + minutes;
      var dateString = year + '-' + month  + '-' + day +" 00:00:00";
      if(a == 1){ //시간값
        dateString = (year+1) + '-' + month  + '-' + day  +" 00:00:00";
      }

        return dateString
      },
      goRouter(path) {
        this.$router.push({path: path, query: { tag: this.tag }})
      },
      getSelectedCountry(){
        this.country_area_phone_option = [];   
        this.country_area_phone = ''
          //this.i_device_type = this.properties['device_type'] 
        this.$http.get(this.URL+'api/users/get_country_area_list?phone_code='+this.country_phone).then((rows) => {
          if((!rows)||(rows.data.result<=0)) return;
          for(let i=0; i<rows.data.result.length; i++) {
            var area_no = rows.data.result[i].code
            var area_phone = area_no.toString().padStart(3, '0');
            var area_name = "(" + area_phone+ ")" + rows.data.result[i].korean_area_name ;
            this.country_area_phone_option.push({value: area_phone , text : area_name}) ;
            if(i ===0 )
             this.country_area_phone = area_phone ;    
          }   
            
        })


      },
      onRowClick(params) {
        //선택된 색상 보여지게
         this.new_show =true;
         this.coord_point = [] ;
        if(this.clicked_b_row != null){
          this.clicked_b_row.bgColor = '';
        //  this.clicked_b_row.style.backgroundColor = '';
        }
      
        if(params.event.target.nodeName==='TD'){
            params.event.target.parentElement.bgColor  = '0e5340';          
            this.clicked_b_row = params.event.target.parentElement ;
        }else  if(params.event.target.nodeName==='IMG'){
            params.event.target.parentElement.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
        }else{
            params.event.target.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement ;
        }
  
        this.properties = params.row ;
        this.properties_bak = Object.assign({}, this.properties) ;

        if(this.properties['image_file_id'] === null || this.properties['image_file_id'] === undefined){
          this.image_item = require('@/assets/noimage1.png')
        }else{
          this.image_item = this.URL+'api' +this.properties['image_file_id']
        }


        var d_sn = this.properties['customer_no'].split("-")
        if(d_sn.length >2){
          this.country_phone = d_sn[0]
          this.country_area_phone = d_sn[1];
          this.country_area_seq = d_sn[2];
        }      
     
   
        this.clicked_l_idx = params.row.customer_no
       
    },
    change_pos(){
      this.$refs.LocationPop.show(this.properties['shape'],this.properties['name']);
    },
    imageSizeChange( image ) {
        this.imgUrl = null;
        let canvas = document.createElement("canvas"),
            max_size = 512,
            // 최대 기준을 1280으로 잡음.
            width = image.width,
            height = image.height;
        if (width > height) {
            // 가로가 길 경우
            if (width > max_size) {
            height *= max_size / width;
            width = max_size;
            }
        } else {
            // 세로가 길 경우
            if (height > max_size) {
            width *= max_size / height;
            height = max_size;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        this.imgUrl = canvas.toDataURL("image/jpeg", 0.5);
        // const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
      //  return dataURItoBlob(dataUrl);
    },        
    previewMultiImage(event) {
        var input = event.target;
        var count = input.files.length;
        var index = 0;
        var self = this;
        if (input.files) {
            while(count --) {
                if (!/^image\//.test(input.files[index].type)) {
                    alert('Only image files can be registered.')
                    return false
                }
                var reader = new FileReader();
                reader.onload = (e) => {
                  //  this.preview_list.push(e.target.result);
                  
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
               
                        self.imageSizeChange(image);
                        self.image_item = self.imgUrl
                       // self.preview_list.push(self.imgUrl)                           
                  
                    };
                    
                    //this.preview_list.push(image.src)
                }
     
                this.call_compress(input.files[index]) ;
                reader.readAsDataURL(input.files[index]);
                
                // reader.readAsDataURL(input.files[index]);
                index ++;
            }
        }
          ++this.listKey;
    },
    async call_compress(files){
        // var new_file = null
        this.image_list = []
        var self = this;
        const options = {
                maxWidth: 700,
                maxHeight: 700,
                success: function (result) {
                    if (result.size > 5*1024*1024) { // 리사이징 했는데도 용량이 큰 경우
                        alert('파일 용량이 초과되어 업로드가 불가 합니다.');
                        return;
                    }
                      console.log('Output: ', result);
                    // return new File([result], result.name, { type: result.type });
                    self.image_list.push( new File([result], result.name, { type: result.type }));

                },
                error: function (err) {
                    console.log(err);
                }
            };
            await new Compressor(files, options);
            //return new_file;
    },
    set_coordiate(cod_point){
      alert(cod_point)
      this.coord_point = cod_point
    },
    new_customer(){
      //새로운 장비 등록
      var today = new Date(); 
      var s_today = this.current_date(today,0);
      var s_today_1 = this.current_date(today,1);
      this.new_show =false;
      this.properties = {} ;
      //this.properties['customer_no'] = '082-042-000';      
      this.country_phone = '' ;
      this.country_area_phone = '';
      this.country_area_seq = '';
      this.image_item = '' ;
      this.properties['sw_license_type'] = '0' ;
      this.properties['service_start_date'] = s_today ;
      this.properties['service_end_date'] = s_today_1 ;
      this.properties['sw_license_count'] = '10' 
      this.properties['status'] = 'A'

    },
    Update(){
      let params = {} ; 
      let keys = Object.keys(this.properties_bak)


      if(this.properties.shape !== undefined && this.properties.shape !== null){

        if(this.coord_point.length > 1){
        //x,y좌표값 체크
          if(this.properties.shape.x !== this.coord_point[0] || this.properties.shape.y !== this.coord_point[1]){
            params['x'] = this.coord_point[0].toString()
            params['y'] = this.coord_point[1].toString()
            this.properties['x'] = this.coord_point[0].toString()
            this.properties['y'] = this.coord_point[1].toString()
          }
        }
      }else{
        if(this.coord_point.length > 1){
          params['x'] = this.coord_point[0].toString()
          params['y'] = this.coord_point[1].toString()
          this.properties['x'] = this.coord_point[0].toString()
          this.properties['y'] = this.coord_point[1].toString()
        }
      }
     

      if(!this.new_show){ //신규등록일시
        for(var i = 0 ; i < this.i_customer_name.length ; ++i ){
          var c_no =this.i_customer_name[i].value ;
          if(c_no === this.properties['customer_no']){
            this.properties['customer_id'] = this.i_customer_name[i].id;
            params['customer_id'] = this.i_customer_name[i].id;
          }
        }
        this.properties['country_no'] = this.country_phone
        this.properties['region_no'] = this.country_area_phone
        if(this.properties['country_no'] === '' || this.properties['region_no'] === ''  ) {
          alert("country, area 선택은 필수 입력 사항입니다.")
          return
        }      
        params = this.properties

        //신규등록일시
        this.$http.post(this.URL+'api/join_customer',params).then((res) => {
            console.log(res.data);
            if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
            }else{
              //이미지 등록  
              var id = res.data.customer_id
              this.insert_customer_icon(id)
              this.go_search();
            }
        })
        
      
      }else{  //update   
       
        if(this.properties['customer_no'] === undefined) {
          alert("고객사선택, 유일한 serial no가 필수 입력 사항입니다.")
          return
        }
        var status_update = false
        for(let j=0; j<keys.length; j++) {
          if(this.properties_bak[keys[j]]===this.properties[keys[j]]) continue          
          params[keys[j]] = this.properties[keys[j]]
          if(keys[j] === "status") status_update = true
        }    
        //status만 별도로 update한다.
        if(status_update){
          this.$http.post(this.URL+'api/set_customer_status', this.properties).then((res) => {
              console.log(res.data);
              if(res.data.check ===1){
                alert("fail : "+ res.data.msg) ;
              }else{
                alert("STATUS SUCCESS!!") ;
              }
          })          
        }
        //이미지를 먼저 upload한다.
        this.insert_customer_icon(this.properties['id'])
        if(this.image_list.length > 0 && Object.keys(params).length<=0){
          this.go_search();          
        }
        if(Object.keys(params).length<=0) return
        this.$http.post(this.URL+'api/set_customer', this.properties).then((res) => {
            console.log(res.data);
            if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
            }else{
              
              this.go_search();
            }
        })
        //이미지 등록  
       
      }
      
 
    },
    insert_customer_icon(id){
      var customer_member_id = sessionStorage.m_idx
      let formData = new FormData();
      formData.append("customer_id", id );   
      formData.append("customer_member_id", customer_member_id );
   
      if( this.image_list.length < 1) return;
      for( var i = 0; i < this.image_list.length; i++ ){
          let file = this.image_list[i];
     
         // formData.append('files[' + i + ']', file);
          formData.append('img_files', file);
  
      }

        
   
      this.$http.post(this.URL+'api/customer_icon', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then((res) => {
          console.log("success")
          if(res.data.check ===1){
              console.log("")
              alert("fail : "+ res.data.msg) ;
          }
          
      })
      .catch(() => {
          console.log('FAILURE!!');
          
      });

    },
    go_search() {
       //if(value===undefined) return;
                //left메뉴 데이터를 뿌려준다.
       //},
        this.new_show =true;
        this.table_device = []; 

        this.$refs.table_customer.setUrl(this.URL+"api/get_customer_list", (rows1) => {
            if((!rows1)||(rows1.length<=0)) return;
            this.clicked_l_idx = rows1[0].id
            this.properties =  rows1[0];
            this.properties_bak = Object.assign({}, this.properties)
            this.image_item = this.URL+'api' +this.properties['image_file_id']
            var d_sn = this.properties['customer_no'].split("-")
            if(d_sn.length >2){
              this.country_phone = d_sn[0]
              this.country_area_phone = d_sn[1];
              this.country_area_seq = d_sn[2];
            }      
        })
   
      },
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
      
       this.country_area_phone_option = [];    
       this.country_area = [];     

       this.image_item = require('@/assets/noimage1.png')
    
       this.$http.get(this.URL+'api/users/get_country_list').then((rows) => {
          if((!rows)||(rows.data.result<=0)) return;
          for(let i=0; i<rows.data.result.length; i++) {
            var p_no = rows.data.result[i].intl_phone_code
            var s_phone = p_no.toString().padStart(3, '0');
            var s_name = "(" + s_phone+ ")" + rows.data.result[i].korean_name ;
            this.country_phone_option.push({value: s_phone , text : s_name}) ;
          }          
        })
    
       /*
        this.$http.get(this.URL+'api/get_customer_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          this.table_customer = rows.data.list;
          
          for(let i=0; i<rows.data.list.length; i++) {
            
            if(i=== 0){
              //  this.$http.get(this.URL+'api/get_device_list_ex?customer_no='+rows.data.list[i].customer_no).then((rows1) => {
              //     this.table_device = rows1.data.list;
              //  })
                this.customer_name.push({value:'', text :'ALL'}) ; 
        
            }
            this.customer_name.push({value:rows.data.list[i].customer_no, text :rows.data.list[i].name}) ; 
            this.i_customer_name.push({value:rows.data.list[i].customer_no, text :rows.data.list[i].name,id:rows.data.list[i].id}) ; 
            
          }
         
        })
        */
        this.$refs.table_customer.setUrl(this.URL+"api/get_customer_list", (rows1) => {
            if((!rows1)||(rows1.length<=0)) return;
            this.clicked_l_idx = rows1[0].id
            this.properties =  rows1[0];
            this.properties_bak = Object.assign({}, this.properties)
            this.image_item = this.URL+'api' +this.properties['image_file_id']
            var d_sn = this.properties['customer_no'].split("-")
            if(d_sn.length >2){
              this.country_phone = d_sn[0]
              this.country_area_phone = d_sn[1];
              this.country_area_seq = d_sn[2];
            }      
        })
   

       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               

           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
      ServerSideTable: ServerSideTable,
      LocationPop : LocationPop,
    }
}
</script>

<style lang="scss">
.device_table .vgt-inner-wrap{
  overflow-y: hidden;
  height: 100%;
}
.presschart_m2_d .wavchart  {
  height: 350px;
  width: 98%;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

//.specification_btn .btn-group-toggle .btn-group-toggle {
  //margin-left: 5px;
//}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: .2em;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 .search_box.device {
    position: absolute;
    top: 58px;
    left: 30px;
    width:30%;
}
</style>
