<template>
    <div>
        <!-- top-bar -->
        <!-- 2022 new -->
      <b-modal
            ref="modal"
            title=""
            >
     <!-- tom-bar-end -->
        
        <div v-if="show_modal" style="margin-left:15px;width:90%;"  >
         
 
              <b-col class="pressure-detail-content-map" style="padding-left: 0px; margin-left: 15px;margin-top:10px;">
                <div class="search_div_press m2_flow ">
                    <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                    <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                  </div>
                <div style="height:70vh;" :key = "listkey" >
                  <Map
                      ref="map"
                      :visible_gisload_button="true"     
                      :press_icon1 ="false"      
                      @set_map_gisload="set_map_gisload"
                      @facility_invisible_layer = "facility_invisible_layer"
                      @set_info="set_info"
                      @go_home="go_home()"                      
                      @set_coordiate="set_coordiate"
                    />
                </div>
              </b-col>
          

        </div>
        <template v-slot:modal-footer="{ ok, cancel, hide }">   
            <b-row class="modal-footer-row m-0 leak_foot">
                <b-col sm="6">
                    <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
                </b-col>
                <b-col sm="6">
                    <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
                </b-col>
            </b-row>
         </template>
      </b-modal>
    </div>
</template>

<script>
import Map from '@/components/Map_m';
import {CreateVectorLayer_f} from '@/gis.js';

export default {
    name: 'Popup Map',
    props: [
    ],
    watch: {
      pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      }, 
      min() {
        if(this.vchart===0) {
          return;
        }
        
         this.chang_bar_url();
      },  
      tabIndex(){
         if(this.tabIndex === 0 ){
            this.get_time_series_mnf();
         }else{
            this.get_time_series_mnf();
            this.get_time_series_flow();
         }
      }   
    },
    data() {
        return {
            bar_url : '',
            bar_url1 : '',
            vchart : 1,
            min : 20,
            m_min : 20,                        
            real_url : '',
            contents_height: 600,
            mnfchart_height: 300,
            map_height: 600,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            pressure_value_type : '',
            pressure_value_opt: ['mnf' , 'flow'],   
            tabIndex : 0,
            listkey : 0,
            selected_ts_flow: '1440',
            flow_date: this.$route.query.last_date,
            loaded_ts_flow: false,

            selected_ts_mnf: '30',
            mnf_date: this.$route.query.last_date,
            addr_value:'',
            show_modal : false,
            coord_point : null,
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
            table_mnf: [],
            ts_mnf: [],
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'blue'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              }
            ],
           
        }
    },
    methods: {
        close(){
            this.$refs.modal.hide()
            //window.close();
        },
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag, last_date : this.$route.query.last_date }})
        },
         addr_search(){
           this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
            this.addr_search();
        },
        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          //this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
          //this.$refs.map.time_30m = this.flow_date; //지도에서 일데이터 (최종시간 그전날 데이터)
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },  


        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            //var size = [size1[0]*2, size1[1]*2] ;
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },   
       toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;            
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },                
       facility_invisible_layer() {

          this.layer_all_visible = false;
          let map = this.$refs.map.map
          for(let i=1; i<map.getLayers().getLength(); i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')>50) continue;
            layer.setVisible(this.layer_all_visible)
          }
        },        

        set_info(){
            this.$refs.map.select_info = true;
        },                           
        async show(shape,keywords) {
          /*
          setTimeout(()=>{
            let setting = this.$store.setting
            if(setting) {
                  this.pressure_value_type = 'mnf'
            }
           }, 2000)
           */
           this.show_modal = true;
           this.$refs.modal.show();
          
           this.map_height = 700
            if(sessionStorage === null || typeof sessionStorage === undefined){
                return      
            }else if(sessionStorage.level < 2){
                this.f_satus_level = false;
            }
        
            if(this.listkey === 0 ){
              ++this.listkey;              
            }else{  
              setTimeout(()=>{
                ++this.listkey;
              }, 500)
            }
           
            //setTimeout(()=>{
              if(shape === null || shape === undefined){
                alert(keywords)
                //this.$refs.map.show_geolocation();
                var coord = await this.startTracking();
                setTimeout(()=>{
                  if(coord !== null && coord !== undefined){
                    this.$refs.map.set_marker_(coord[0],coord[1])
                  }else{
                    if(keywords === null || keywords === undefined || keywords === "" )
                      keywords = 'seoul'
                    this.$refs.map.go_search(keywords);
                  }
                  this.$refs.map.init_draw_modify();
                  this.$refs.map.edit_change('modify', '')
               }, 1000)
              }else{
                var py = shape.y   //y값
                var px = shape.x    //x값
                alert(py+','+px)
                setTimeout(()=>{
                  this.$refs.map.set_marker_(px,py);
                  this.$refs.map.init_draw_modify();
                  this.$refs.map.edit_change('modify', '')
                }, 1000)
              }

              
           //  }, 1000)
            //this.$refs.map.update_size()
         
        
             
        },
        async startTracking() {
           // const status = document.getElementById("status");

            // Geolocation API 지원 여부 확인
            var coor = null
            if ("geolocation" in navigator) {
                navigator.geolocation.watchPosition(
                    (position) => {
                        const { latitude, longitude, accuracy } = position.coords;
                        console.log( `위도: ${latitude}, 경도: ${longitude}, 정확도: ${accuracy}미터`);
                        //this.$refs.map.set_marker_(longitude,latitude)
                        coor = [longitude,latitude]
                        return coor
                    },
                    (error) => {
                        console.log( `위치 정보를 가져올 수 없습니다: ${error.message}`);
                        return null
                    },
                    {
                        enableHighAccuracy: false, // 정확도 우선 모드
                        timeout: 10000,           // 10초 이내에 응답 없으면 에러 발생
                        maximumAge: 0             // 항상 최신 위치 정보 수집
                    }
                );
            } else {
                console.log( "브라우저가 위치 서비스를 지원하지 않습니다.");
                return null
            }

           // return coor
        },
        set_coordiate(cod_point){
          //alert(cod_point)
          this.coord_point = cod_point
        },
         apply() {
            
            // if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
            // }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            alert(this.coord_point)
            this.$emit('set_coordiate',this.coord_point)
            /*
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
            }
            if(Object.keys(params).length<=0) return
                this.$http.get(this.URL+'api/update_water_leak?type=logger&idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
            })
          //  window.close();
            this.$emit('refresh_table') ;
            */
            this.$refs.modal.hide();
        }  
    },
    mounted () {
      // this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175

       this.map_height = window.innerHeight - 250
       /*
       setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
                this.pressure_value_type = 'mnf'
          }
        }, 2000)
        */
   //left메뉴 데이터를 뿌려준다.

    },
    beforeDestroy () {
    },
    components: {
        Map: Map,    
    }
}
</script>

<style lang="scss" scoped>
.search_div_press.m2_flow {
  left: 170px;
} 
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // 2022 new
  // background: #fff;
  background: #172A46;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  // float: left;
  // width: 42px;
  // height: 32px;
  // line-height: 32px;
  // text-align: center;
  // border-radius: 2px;
  // background: rgba(35,41,47,0.4);
  // font-size: 14px;
  // margin-right: 16px;
  // cursor: pointer;
  // border: 0;
   display: inline-block;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #707070;
    text-align: center;
    vertical-align: middle;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background-color: #28AF81;;
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  // background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  // border-radius: 2px;
  text-align: center;
  // color: #fff;
  font-size: 14px;
  cursor: pointer;
}



.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  // background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 745px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
//  2022 new

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    /* background-color: #fff; */
    /* border-color: #dee2e6 #dee2e6 #fff; */
    border: 1px solid #28AF81;
    background-color: #28AF81;
}
.color-content-bg{
       background: #172A46;
}
.btn-primary{
  position: absolute;
    right: 0;
    top: 0;
}

#wavchart .highcharts-container {
  /*min-height: 350px;*/
 
  width: 100%;
}
</style>
